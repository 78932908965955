// import react from 'react';

import ContactForm from "../Components/ContactForm";
import Scrollbtn from "../Components/Scrollbtn";

function Contact(){

    return(<>
    <ContactForm/>
    <Scrollbtn/>
    </>);
};
export default Contact;